import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "SubcomHome",
    component: () => import("../views/SubcomHome"),
  },
  {
    path: "/metrics-browser",
    name: "VictoriaMetrics",
    component: () => import("../views/VictoriaMetrics"),
  },
  {
    path: "/endpoints-browser",
    name: "EndpointsBrowser",
    component: () => import("../views/EndpointsBrowser"),
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: () => import("../views/NotFound/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
